
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo, DicInfo } from '@/types/common'
import MapLocation from '@/components/mapLocation/Index.vue'
import { LocationData, DetailInfo } from '@/types/serviceFacility'
import UploadFile from '@/components/uploadFile/Index.vue'
import { pInteger, phoneAndFixedLine } from '@/utils/validate'

@Component({
  name: 'ServiceFacilityAdd',
  components: { MapLocation, UploadFile }
})
export default class extends Vue {
  private formData: DetailInfo = {
    projectId: '',
    serviceName: '',
    buildDate: '',
    description: '',
    resourcesLocationList: [],
    resourceList: [],
    locationType: '',
    parkingNum: '',
    hospitalPhone: '',
    isNetRed: ''
  }

  private locationTypeList: Array<DicInfo> = []
  private locationTypeName = ''
  private resourcesLocationList: LocationData[] | null = []
  private submitDisabled = false

  private rulesForm = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    description: [{ required: true, message: '请填写描述', trigger: 'blur' }],
    serviceName: [
      { required: true, message: '请填写设施名称', trigger: 'blur' }
    ],
    parkingNum: [
      { validator: pInteger, trigger: 'blur' }
    ],
    hospitalPhone: [
      { validator: phoneAndFixedLine, message: '支持手机、固话示例02588888888', trigger: ['change', 'blur'] }
    ],
    buildDate: [
      { required: true, message: '请选择建设日期', trigger: 'change' }
    ],
    resourcesLocationList: [
      { required: true, message: '请选择坐标位置', trigger: 'change' }
    ],
    resourceList: [{ required: true, message: '请上传图片', trigger: 'change' }],
    isNetRed: [
      { required: true, message: '请选择网红景点', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getlocationType()
    const id = this.$route.params.id
    if (id) {
      // 编辑
      this.getDetail(id)
    }
  }

  // 设施类型切换
  locationTypeChange (val: string) {
    if (val) {
      this.locationTypeName = this.locationTypeList.filter(item => item.dicCode === val)[0].dicValue || ''
    } else {
      this.locationTypeName = ''
    }
  }

  // 经纬度
  locationChange (arr: LocationData[]) {
    this.formData.resourcesLocationList = arr
  }

  confirmAdd () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitDisabled = true
        const urls = this.$route.params.id ? this.$apis.serviceFacility.updateServiceFacilities : this.$apis.serviceFacility.insertServiceFacilities
        this.$axios.post(urls, this.formData).then((res: any) => {
          const text = this.$route.params.id ? '编辑成功' : '新增成功'
          this.$message.success(text)
          this.$router.push({ path: '/serviceFacility' })
        }).finally(() => {
          this.submitDisabled = false
        })
      }
    })
  }

  imgRemove (index: number) {
    this.formData.resourceList.splice(index, 1)
  }

  uploadSuccess (file: FileInfo) {
    this.formData.resourceList.push(file)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.serviceFacility.selectServiceFacilitiesByServiceId, {
        serviceId: id
      })
      .then((res) => {
        this.resourcesLocationList = res.resourcesLocationList
        this.formData = res
        this.locationTypeName = res.locationTypeName || ''
      })
  }

  getlocationType () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'locationType'
    }).then(res => {
      this.locationTypeList = res.locationType || []
    })
  }
}
